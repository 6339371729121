/* About section */

.about-container {
    background-color: #3f3f3f;
    color: #c5c5c5;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    z-index: 1;
    margin-top: 0;
    height: auto;
    max-height: 80vh;
    width: 75%;
    padding: 2rem 1rem;
    /* added some padding for smaller screens */
}


.about-container h2 {
    font-size: 2rem;
    /* adjust font-size for mobile */
    font-weight: bold;
    margin-bottom: 0.25rem;
    color: #ffc300;
    -webkit-text-stroke: 0.5px grey;
    letter-spacing: 0.1em;
}

.about-section {
    padding: 2rem;
    /* adjust padding for mobile */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 66vh;
    
    overflow-y: auto;
}


.about-section p {
    font-size: 1rem;
    /* adjust font-size for mobile */
    margin-bottom: 1rem;
    line-height: 1.5;
}
/* Larger screens (e.g., tablets) */
@media screen and (min-width: 768px) {
    .about-container {
        padding: 3rem 2rem;
        /* increase padding a bit */
    }

    .about-section {
        padding: 4rem;
    }

    .about-section h2 {
        font-size: 2.5rem;
    }

    .about-section p {
        font-size: 1.1rem;
    }
}

/* Desktops */
@media screen and (min-width: 1024px) {
    .about-section h2 {
        font-size: 3rem;
    }

    .about-section p {
        font-size: 1.2rem;
    }
}
.cta-button {
    display: inline-block;
    background-color: #ffde59;
    color: #333;
    padding: 8px 16px; /* Adjusted padding for mobile */
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.cta-button:hover {
    background-color: #ffc300;
    color: #222222;
    text-decoration: none;
}

/* Modal base styles */
.modal {
    position: fixed; /* Stay in place */
    z-index: 99999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close-button {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close-button:hover,
.close-button:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}


@media screen and (max-width: 767px) {
    .about-section {
        padding-top: 5rem;
    }
}
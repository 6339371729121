.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    background-color: #3f3f3fab; /* Using the background color from your .App class */
    width: 100vw;
    height: 7vh;
    position: relative; /* This can help in ensuring the footer stays at the bottom, especially on shorter content pages */
}

.icons {
    transition: color 0.3s;
    text-decoration: none; /* Removes any default underlines on the anchor tags */
    display: flex; /* Makes sure any internal elements inside the link (like SVGs) are aligned properly */
    align-items: center; /* Vertical alignment for internal elements */
    justify-content: center; /* Horizontal alignment for internal elements */
}

.footer-link {
    padding-left: 5rem;
    padding-right: 5rem;
    color: #67d1ee; /* Used the App-link color for consistency */
    font-size: 1.5rem;
}
.copyright {
    color: #6d6d6d; /* Used the App-link color for consistency */
    font-size:0.5rem;
    /* margin: 0 auto; */
    display: flex; /* Makes sure any internal elements inside the link (like SVGs) are aligned properly */
    align-items: center; /* Vertical alignment for internal elements */
    justify-content: center; /* Horizontal alignment for internal elements */
}

.footer-link:hover {
    color: #ffde59; 
}

@media screen and (max-width: 760px) {
    .footer-link {
        color: #61dafb; /* Used the App-link color for consistency */
        font-size: 1.5rem;
        margin: 0 0.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        transition: color 0.3s;
        text-decoration: none; /* Removes any default underlines on the anchor tags */
        display: flex; /* Makes sure any internal elements inside the link (like SVGs) are aligned properly */
        align-items: center; /* Vertical alignment for internal elements */
        justify-content: center; /* Horizontal alignment for internal elements */
    }
}

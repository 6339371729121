.navbar .nav-button {
  background: transparent;
  border: none;
  color: darkgray;
  cursor: pointer;
  position: relative;
  padding: 0.5rem 1rem;
  font-size: 0.8rem; /* keeping the same font size as before */
  text-decoration: none;
  overflow: hidden;
  transition: color 0.3s;
}

.navbar .nav-button::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: black;
  bottom: 0;
  left: 0;
  transition: width 0.3s;
}

.navbar .nav-button:focus,
.navbar .nav-button:active {
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  color: darkgray !important;
}

.navbar .nav-button:hover {
  color: #ffc300 !important;
  background: transparent !important;
}

.navbar .nav-button:hover::after,
.navbar .nav-button:focus::after {
  width: 100%;
}
.service-container {
    background-color: #3f3f3f;
    color: #c5c5c5;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    z-index: 1;
    max-height: 80vh;
    height: auto;
    width: 75%;
    padding: 3rem 2rem;
    /* added some padding for smaller screens */
}

#services {
    overflow-y: auto;
}

.services-section {
    /* adjust padding for mobile */
    display:inline-flex;
    flex-direction: row;
    gap: 2rem; /* Adds some space between each service */
    padding: 2rem;
    width: 100%;
    max-height: 70vh;
    justify-content: center;
    align-items: center;
}

.service-container h2 {
    font-size: 2rem;
    /* adjust font-size for mobile */
    font-weight: bold;
    margin-bottom: 1rem;
    color: #ffc300;
    -webkit-text-stroke: 0.5px grey;
    letter-spacing: 0.1em;
}

.services-section p {
    font-size: 1rem;
    /* adjust font-size for mobile */
    margin-bottom: 1rem;
    line-height: 1.5;
}

.individual-service {
    width: 100%; /* Makes sure each service takes up the full width on mobile */
    padding: 1rem;
    text-align: center; /* Centers the text for each service */
}

.individual-service h3 {
    color: #ffc300;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

@media screen and (max-width: 380px) {
    .individual-service p {
        font-size: 0.75rem;
        
    }
    #services h2 {
        font-size: 1.75rem;
    }
    .services-section {
        flex-direction: column; /* Changes the direction for larger screens */
    }
    
}
@media screen and (max-width: 450px) {
    .services-section {
        padding-top: 1vh;
        flex-direction: column; /* Changes the direction for larger screens */
    }
    
}
/* Scroll to Top Button */
.scrollToTop {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ffc300;
  color: #2c2c2c;
  padding: 10px;
  border: none;
  cursor: pointer;
  z-index: 1;
}
.App {
  font-family: 'Nunito', sans-serif;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  position: relative;
  overflow-y: auto;
}

.App::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: radial-gradient(circle at center, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0)); */
  background: url('../public/beanstalk-dark.webp');
  /* background: url('../public/beanstalk-dark.webp'), radial-gradient(circle at center, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0)); */
  /* background: radial-gradient(circle at center, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0)); */
  z-index: 0;
  /* Places the circle behind the intro-section */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 20vh;
  width: 95%;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.intro-section {
  padding: 1rem;
  /* adjusted padding for mobile */
  background-color: rgba(26, 26, 26, 0.7);
  border-radius: 20px;
  margin: 1rem;
  /* adjusted margin for mobile */
  z-index: 1;
  box-shadow: 0px 10px 10px rgba(32, 32, 32, 0.5);
  width: auto;
}

.component {
  border-radius: 20px;
  z-index: 1;
  width: 100vw;
  height: auto;
  max-height: 97vh;
}

.snap-container {
  /* Set up the container to scroll vertically */
  overflow-y: scroll;
  height: 93vh; 
  /* Assuming you want viewport height sections */

  /* Set up snap scrolling */
  scroll-snap-type: y mandatory;
}

.snap-child {
  height: 90vh;
  scroll-snap-align: start;
}

.title-text {
  font-size: 4rem;
  line-height: 1;
  font-weight: 800;
  color: white;
  text-shadow: 2px 2px 4px #000000;
  padding: 0.4em auto;
}

#gold {
  color: #ffc300;
}

.slogan-text {
  font-size: 1.5rem;
  /* adjusted font-size for mobile */
  color: #ffffff;
  text-shadow: 1px 1px 2px #000000;
  /* adjusted width for mobile */
  margin: 0 auto;
}

.nav-links {
  font-size: 1.25rem;
  color: #ffc300;
  margin-top: 2rem;
  letter-spacing: 0.25rem;
}

.nav-links button {
  background-color: transparent; 
  border: none; 
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 5px;
  padding: 0.2rem; 
  transition: color 0.3s;
  
  /* Setting the gradient from #ffde59 to darkgray */
  background: linear-gradient(90deg, #ffc300 50%, darkgray 50%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 0.3s;
}

.nav-links button:hover {
  background-position: 0% 100%;
}

.nav-links .btn-link.active {
  /* Your styles for active state here */
  color: #ffc300;
}

.nav-links span {
  margin-left: 1rem;
  margin-right: 1rem;
}

/* For even larger screens (e.g., desktops) */
@media screen and (min-width: 1024px) {
  .intro-section {
    padding: 4rem;
    margin: 2rem;
    width: 66%;
  }

  .title-text {
    font-size: 8rem;
    padding: 1.5rem;
  }

  .slogan-text {
    font-size: 2rem;
    width: 50%;
  }

  .nav-links {
    font-size: 2rem;
  }
}

/* For larger screens (e.g., tablets, small laptops) */
@media screen and (max-width: 765px) {
  .intro-section {
    padding: 5rem;
    margin: 4rem;
    
    width: 66%;
  }

  .title-text {
    font-size: 3rem;
    padding: 1em;
  }

  .slogan-text {
    font-size: 1rem;
    width: 80%;
  }

  .nav-links {
    font-size: 0.9rem;
    padding: 0;
  }

  .nav-links span {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
}